import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token:"",
    baseurl:"https://oa123.hanbio.net",
    currpage:0
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
