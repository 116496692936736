import Vue from 'vue';
import axios from 'axios';
import qs from 'qs'; //引入qs，axios在post参数时，要通过qs来格式化数据

// 不需要每次都 import一下 axios了，直接使用 $axios 即可
Vue.prototype.$axios = axios;
// step3：使每次请求都会带一个 /api 前缀 
axios.defaults.baseURL = 'https://oa123.hanbio.net/appQuery/appquery.ashx';//'http://192.168.2.4:48486/appQuery/appquery.ashx';//

// 添加请求拦截器
axios.interceptors.request.use(
	config => {
		if (config.method == 'post') {
			config.headers = {
				'content-Type': 'application/x-www-form-urlencoded'
			}
			config.data = qs.stringify(config.data); //对参数进行序列化
		}
		return config;
	},
	error => {
		console.log('请求出错啦');
		console.dir(error)
		return Promise.reject(error);
	}
);

// 添加响应拦截器
axios.interceptors.response.use(
	response => {
		if (response.data) {
			if (response.data.code == 2 || response.data.code == 4) {
				localStorage.setItem("hanbio_app_token", "");
				Vue.prototype.$bus.$emit("offline", 0);
			}
		}
		return response;
	},
	error => {
		return Promise.reject(error);
	}
);
Vue.prototype.$axios = axios;

 