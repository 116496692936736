import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import './registerServiceWorker'
import Vant from 'vant';
import 'vant/lib/index.css';
import "@/assets/font/iconfont.css";
import '@/axios'; //引入axios
import "@/assets/style.css";
import vueMiniPlayer from 'vue-mini-player'
import 'vue-mini-player/lib/vue-mini-player.css'
import { utils } from '@/common';
Vue.use(vueMiniPlayer)
Vue.use(Vant);
Vue.config.productionTip = false
import LongPress from './longpress.js'
Vue.use(LongPress, { time: 1000 })
import VueClipboards from 'vue-clipboard2'
Vue.use(VueClipboards);

import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
Vue.use(VueViewer)
VueViewer.setDefaults({   
  toolbar: {
    oneToOne: true,
    prev: true,
    play: true,
    next: true,
    download: () => {
      const viewer = this.$viewer;
      utils.downImg(viewer.image.src);
    }
  }
});

// 使用 vue-meta
import Meta from "vue-meta";
Vue.use(Meta);


new Vue({
  store,
  router,
  render: h => h(App),
  beforeCreate() {
    Vue.prototype.$bus = this	//安装全局事件总线
  }
}).$mount('#app')
