<template>
  <div id="app">
    <Keep-alive :include="include">
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </Keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>
<script>
import 'viewerjs/dist/viewer.css'
import '@/assets/style.css'
import '@/assets/fontawesome/css/font-awesome.css'
import '@/assets/font/iconfont.css'
export default {
  name: "app",
  data: () => ({
    include: []
  }),
  mounted() {
    // 绑定自定义事件
    this.$bus.$on('offline', (e) => {
      console.log('触发登录失效事件', e);
      this.$router.replace({ name: "login_view", });
    })

    document.documentElement.addEventListener(
      "touchstart",
      function (event) {
        if (event.touches.length > 1) {
          event.preventDefault();
        }
      },
      { passive: false }
    );

    // 禁止双击放大
    let lastTouchEnd = 0;
    document.documentElement.addEventListener(
      "touchend",
      function (event) {
        var now = Date.now();
        if (now - lastTouchEnd <= 300) {
          event.preventDefault();
        }
        lastTouchEnd = now;
      },
      { passive: false }
    );

  },
  // metaInfo: {    
  //   meta: [
  //     { name: "viewport", content: "width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0" },     
  //   ],
  // },
  watch: {
    $route(to, from) {
      //如果 要 to(进入) 的页面是需要 keepAlive 缓存的，把 name push 进 include数组
      if (to.meta.keepAlive) {
        !this.include.includes(to.name) && this.include.push(to.name);
      }
      //如果 要 form(离开) 的页面是 keepAlive缓存的，
      //再根据 deepth 来判断是前进还是后退
      //如果是后退
      console.log(this.include);
      if (from.meta.keepAlive && to.meta.deepth < from.meta.deepth) {
        var index = this.include.indexOf(from.name);
        index !== -1 && this.include.splice(index, 1);
      }
    }
  }
}; 
</script>
<style>
html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  user-select: none;
  background-color: #f7f8fa;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
.ft14{
  font-size: 14px;
}
</style> 
 